import Utm from "../components/_global/utm"
// images
import TopBackground from "../images/sustainability.jpeg";
import TopBackgroundMobile from "../images/headersMobile/6-sustainability-mobile.jpg";
import Oeko from "../images/sustain-oeko-tex2x.png";
import Gots from "../images/sustain-gots2x.png";
import Wrap from "../images/sustain-wrap2x.png";
import Textile from "../images/textile-exchange2x.png";
import FoursourceCEO from "../images/jonas.png";

// icons
import CheckIcon from "../images/icon/check.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("sustainability.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("sustainability.imgAlt"),
        smallHeight: true
      },
    },
    topInfoMobile: {
      title: {
        text: t("sustainability.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackgroundMobile,
        alt: t("sustainability.imgAlt"),
        smallHeight: true
      },
    },
    firstText: [
      {
        text: t("sustainability.foursourceAim"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    secondText: [
      {
        text: t("sustainability.strongPartnerships"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      {
        text: t("sustainability.signUp"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        textType: "button",
        extraClass: "bigWidth"
      },
    ],
    quote: {
      quote: {
        text: t("sustainability.coopRevolu"),
        colorClass: "colorDark",
      },
      author: {
        name: t("sustainability.authorName"),
        job: t("sustainability.autorJob"),
        image: {
          src: FoursourceCEO,
          alt: t("sustainability.authorImgAlt"),
        },
      },
    },
    sustainabilityPartners: [
      {
        img: {
          src: Oeko,
          alt: t("sustainability.oekoAlt"),
        },
        cta: {
          text: t("sustainability.oekoButton"),
          url: "/sustainability/oekotex-x-foursource/",
          type: "mainColor",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: Gots,
          alt: t("sustainability.gotsAlt"),
        },
        cta: {
          text: t("sustainability.gotsButton"),
          url: "/sustainability/gots-x-foursource/",
          type: "mainColor",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: Wrap,
          alt: t("sustainability.wrapAlt"),
        },
        cta: {
          text: t("sustainability.wrapButton"),
          url: "/sustainability/wrap-x-foursource/",
          type: "mainColor",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: Textile,
          alt: t("sustainability.textileAlt"),
        },
        cta: {
          text: t("sustainability.textileButton"),
          url: "/sustainability/textileexchange-x-foursource/",
          type: "mainColor",
          extraClass: "font14",
        },
      },
    ],
    thirdText: [
      {
        text: t("sustainability.mfUpload"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    sustainabilityTrends: {
      subtitle: {
        text: t("sustainability.sustainabilityTrends"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("sustainability.consumerBehaviour"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    sustainabilityTrendsBullets: [
      {
        text: t("sustainability.twoAreas"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      {
        text: t("sustainability.digitalizationEnables"),
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        colorClass: "colorDark",
        textType: "bullet",
        noSpacing: true,
      },
      {
        text: t("sustainability.integrationSustainability"),
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        colorClass: "colorDark",
        textType: "bullet",
        noSpacing: true,
      },
    ],
    sustainableApparel: {
      subtitle: {
        text: t("sustainability.sustainableApparel"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("sustainability.sustainableFuture"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    sustainableApparelBullets: [
      {
        text: t("sustainability.impactSustainable"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      {
        text: t("sustainability.brandEnvironmental"),
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        colorClass: "colorDark",
        textType: "bullet",
        noSpacing: true,
      },
      {
        text: t("sustainability.sourcingProcess"),
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        colorClass: "colorDark",
        textType: "bullet",
        noSpacing: true,
      },
    ],
    twoCols: [
      {
        customPBText: true,
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [
            t("sustainability.accord"),
            t("sustainability.alliance"),
            t("sustainability.amfori"),
            t("sustainability.bluesign"),
            t("sustainability.fairwear"),
            t("sustainability.ics"),
            t("sustainability.andMore"),
          ],
          url: [
            "https://bangladeshaccord.org/",
            "http://www.bangladeshworkersafety.org/",
            "https://www.amfori.org/content/amfori-bsci",
            "https://www.bluesign.com/en",
            "https://www.fairwear.org/",
            "https://ics-asso.org/",
          ],
          colorClass: "colorDark",
        },
      },
      {
        customPBText: true,
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [
            t("sustainability.isoQuality"),
            t("sustainability.isoEnvironmental"),
            t("sustainability.isoHealth"),
            t("sustainability.isoEnergy"),
            t("sustainability.sa8000"),
            t("sustainability.sedex"),
          ],
          url: [
            "https://www.iso.org/iso-9001-quality-management.html",
            "https://www.iso.org/iso-14001-environmental-management.html",
            "https://www.iso.org/iso-45001-occupational-health-and-safety.html",
            "https://www.iso.org/iso-50001-energy-management.html",
            "http://www.sa-intl.org/index.cfm?fuseaction=Page.ViewPage&PageID=1689",
            "https://www.sedexglobal.com/",
          ],
          colorClass: "colorDark",
        },
      },
    ],
    about: {
      text: t("sustainability.aboutbutton"),
      url: "#sustainability-info",
      type: "mainColor",
      extraClass: "font14",
    },
    signup: {
      text: t("sustainability.signUp"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
      extraClass: "font14 bigWidth",
    },
  };
};
