import * as React from "react";

// components 
import ButtonLink from "./_partials/_buttonLink";

class imgButton extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <>
        <div className="imgButtonContent p-15">
          <div className="img-content">
            <img className="h-100 w-100" alt={info.img.alt} src={info.img.src} />
          </div>
          <div className="row justify-content-center pt-20">
            <ButtonLink text={info.cta.text} url={info.cta.url} type={info.cta.type} extraClass={info.cta.extraClass}></ButtonLink>
          </div>
        </div>
      </>
    );
  }
}

export default imgButton;
