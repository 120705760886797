/**
 * Foursource page
 * https://foursource.com/sustainability
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/sustainability";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Text from "../components/text";
import Quote from "../components/quote";
import ImgButton from "../components/imgButton";
import BulletsSection from "../components/bulletsSection";
import TitleSeparator from "../components/titleSeparator";
import ButtonLink from "../components/_partials/_buttonLink";

const MOBILE_WIDTH = 992;
class sustainabilityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };


  render() {
    return (
      <>
        <Layout pageName="sustainability" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-50">
            <Text text={this.text.firstText}></Text>
            <Quote info={this.text.quote}></Quote>
          </div>
          <div className="paddingSidesBig backgroundGrey topBottomDiagonal pt-30">
            <div className="pt-100 pb-100">
              <Text text={this.text.secondText}></Text>
              <div className="row row-cols-2 row-cols-lg-4 row-cols-md-2 pt-30 pb-10">
                {this.text.sustainabilityPartners.map((partner, i) => (
                  <ImgButton key={i} info={partner}></ImgButton>
                ))}
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pt-30">
            <Text text={this.text.thirdText}></Text>
            <div className="d-table pt-40 row row-cols-md-2 row-cols-sm-1 m-0">
              {this.text.twoCols.map((col, i) => (
                <BulletsSection key={i} info={col} num={i}></BulletsSection>
              ))}
            </div>
            <div className="row justify-content-center pt-10 pb-100">
                  <ButtonLink
                    text={this.text.about.text}
                    url={this.text.about.url}
                    type={this.text.about.type}
                    extraClass={this.text.about.extraClass}
                  ></ButtonLink>
                </div>
          </div>
          <div id="sustainability-info" className="paddingSidesBig backgroundGrey topDiagonal pt-30">
            <div className="pt-100 pb-100">
              <TitleSeparator info={this.text.sustainabilityTrends}></TitleSeparator>
              <div className="pt-15">
                <Text text={this.text.sustainabilityTrendsBullets}></Text>
              </div>
              <div className="pt-30">
                <TitleSeparator info={this.text.sustainableApparel}></TitleSeparator>
                <div className="pt-15">
                  <Text text={this.text.sustainableApparelBullets}></Text>
                </div>
                <div className="row justify-content-center pt-30 pb-30">
                  <ButtonLink
                    text={this.text.signup.text}
                    url={this.text.signup.url}
                    type={this.text.signup.type}
                    extraClass={this.text.signup.extraClass}
                  ></ButtonLink>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(sustainabilityPage);
