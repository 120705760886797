import * as React from "react";
import "../style/components/quote.scss";

class Quote extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <div className="quoteContent pt-50 container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6">
            <div className="quoteBox boxRadius pb-40 pt-40 pl-35 pr-45">
              <blockquote className="font14 colorDark lineHeight1_7">{info.quote.text}</blockquote>
            </div>
            <div className="authorBox">
              <img className="authorImage ml-25" src={info.author.image.src} alt={info.author.image.alt}></img>
              <div className="authorInfo float-right pt-20 pl-20">
                <p className="authorName colorDark font17 fontWeightBold m-0">{info.author.name}</p>
                <p className="authorJob font14 letterSpacing1">{info.author.job}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Quote;
